.dropzone {
  display: flex;

  border: 1px dashed var(--eds-color-grey-2);
  border-radius: var(--eds-radii-4);
  cursor: pointer;
  margin-bottom: var(--eds-space-12);
  padding: 25px var(--eds-space-16);
}

.dropzone:hover {
  border-color: var(--eds-color-border-hover);
}

.dragActive {
  border-color: var(--eds-color-border-focus);
}
