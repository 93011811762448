.content {
  max-width: 1628px;
}

.main {
  margin: var(--eds-space-24) 0;
  padding: 0 var(--eds-space-56);
  max-width: 1500px;
}

@media (min-width: 640px) {
  .content {
    margin: 0 var(--eds-space-24);
  }
}

@media screen and (min-width: 768px) {
  .content {
    margin: 0 auto;
    width: 90%;
  }

  .main {
    margin: var(--eds-space-48) 0;
  }
}

@media screen and (min-width: 1024px) {
  .content {
    margin: 0 auto;
    width: 90%;
  }
}

@media screen and (min-width: 1280px) {
  .content {
    margin: 0 auto;
    width: 80%;
  }

  .main {
    margin: var(--eds-space-48) auto;
  }
}
